import React from 'react';
import ReactTooltip from 'react-tooltip';

import SEO from '../components/SEO';
import Nav from '../components/nav';
import Hero from '../components/Hero-small';
import Changelog from '../components/Changelog';
import Footer from '../components/Footer';

class Index extends React.Component {
  render() {
    return (
      <>
        <SEO title="Changelog" keywords={['']} pathname={this.props.location.pathname} />
        <Nav />
        <Hero />
        <Changelog />
        <Footer />
        <ReactTooltip effect="solid" />
      </>
    );
  }
}

export default Index;
