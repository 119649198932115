import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';



const styles = require('./Changelog-styles.module.css');

const URL = `https://api.github.com/repos/firstversionist/superposition/releases`;

const Changelog = () => {
  const [changelog, setChangelog] = useState('Loading...');

  async function getChangelog() {
    const releases = await fetch(URL).then(response => response.json());

    setChangelog(
      releases.map(release => {
        const releaseDate = new Date(release.published_at);
        return (
          <div className={styles.release} key={release.name}>
            <h2>
              {release.name}{' '}
              <span>
                {releaseDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </span>
            </h2>
            <div className={styles.desc}>
              <Markdown source={release.body} />
            </div>
          </div>
        );
      })
    );
  }

  useEffect(() => {
    getChangelog();
  }, []);

  return (
    <section className={styles.changelog}>
      <h1>Changelog</h1>
      <div className={styles.changelogcontainer}>{changelog}</div>
    </section>
  );
};

export default Changelog;
